class Validator {
	static clearErrors = (input) => {
		$(input).closest(".form-file").find(".invalid-feedback-style").hide();
	}
	static showError = (input, errors) => {
		let message = null;
		errors.forEach((error) => {
			switch (error) {
				case "size":
					message = ".invalid-feedback-size";
					break;
				case "type":
					message = ".invalid-feedback-type";
					break;
			}
			$(input).closest(".form-file").find(message).show();
		});
	}
	static removeFile = (input, preview, previewDefault) => {
		$(input).closest(".form-file").find(".btn-remove").hide();
		$(input).closest(".form-file").find('.file-name').text("");
		$(preview).prop("src", previewDefault);
		$(input).val("");
	}
	static attachFile = (event, file, input, preview) => {
		const reader = new FileReader();
		reader.onload = (event) => {
			$(preview).prop("src", event.target.result);
			if (!file.type.includes("image")) {
				$(preview).prop("src", $(preview).data("src"));
			}
		};
		reader.readAsDataURL(file);

		$(input).closest(".form-file").find('.file-name').text(file.name);
		$(input).closest(".form-file").find(".invalid-feedback-style").hide();
		$(input).closest(".form-file").find(".btn-remove").show();
	}
}

// Init validation - Files
const files = document.querySelectorAll(".form-file");

files.forEach((file) => {
	const input = file.querySelector("input[type=file]");
	const buttonRemove = file.querySelector(".btn-remove");
	const preview = {
		element: file.querySelector("img.file-preview"),
		default: file.querySelector("img.file-preview").src,
	}
	const rules = {
		size: $(input).data("max-size"),
		type: $(input).attr("accept"),
	}

	if (rules.type) {
		file.querySelector(".file-type")
			? file.querySelector(".file-type").innerText = `(${rules.type})`
			: null;
		file.querySelector(".file-type-error")
			? file.querySelector(".file-type-error").innerText = `${rules.type}`
			: null;
	}
	if (rules.size) {
		file.querySelector(".file-size")
			? file.querySelector(".file-size").innerText = `(Max. ${rules.size} MB)`
			: null;
		file.querySelector(".file-size-error")
			? file.querySelector(".file-size-error").innerText = `${rules.size} MB`
			: null;
	}

	$(buttonRemove).on('click', function() {
		Validator.removeFile(input, preview.element, preview.default);
	});

	$(input).on("click", () => {
		Validator.clearErrors(input);
		Validator.removeFile(input, preview.element, preview.default);
	});
	$(input).on("change", (event) => {
		let file = event.target.files[0];
		if (file) {
			errors = [];
			if (file.size > rules.size * 1024 * 1024) {
				errors.push("size");
				Validator.removeFile(input, preview.element, preview.default);
			}
			if (rules.type && !rules.type.includes(file.name.split('.').pop())) {
				errors.push("type");
				Validator.removeFile(input, preview.element, preview.default);
			}
			if (errors.length > 0) {
				Validator.showError(input, errors);
			} else {
				Validator.attachFile(event, file, input, preview.element);
			}
		}
	});
});