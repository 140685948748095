import { setRegisteredData } from "./forms/registered-data";

$('[name="personal_data[billType]"]').on('change', function() {
    $('[name="personal_data[billPos]"]', this.form.elements).closest('.form-group').prop('disabled', $(this).val() != 'Paragon');
}).trigger('change');

$('[name="personal_data[emailRepeat]"]').each(function() {
    let $email = $('[name="personal_data[email]"]', this.form.elements);
    let $emailRepeat = $('[name="personal_data[emailRepeat]"]', this.form.elements);
    $($email).add($emailRepeat).on('input', function() {
        $emailRepeat.toggleValid($email.val() == $emailRepeat.val());
    }).trigger('change');
});

$('.application-form').on('submit-success', function(event, data) {
    $('.application').removeClass('show');

    $('html, body').animate({
        scrollTop: 0
    }, 300);

    if (data.prize) {
        if (data.prize.staticId === 'mugs') {
            $('.prize-info').text('To był dobry ruch. Wygrałeś zestaw kufli!');
        } else {
            $('.prize-info').text('Czech-mate,  wygrałeś! Zestaw szachowy jest Twój!');
        }
        $('.win-thanks').addClass('show');
        setRegisteredData(data, 'section#win-thanks');
    } else {
        $('.loss-thanks').addClass('show');
        setRegisteredData(data, 'section#loss-thanks');
    }
});

$('.game-run').on('click', function() {
    // page swap
    $('.page').addClass('d-none');
    $('.page-result').removeClass('d-none');
    $('.page-result').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    //$('#modal-form-application').modal('show');
});

$('.result-try-again').on('click', function() {
    $('.page').addClass('d-none');
    $('.page-form').removeClass('d-none');
});

$('[name="personal_data[noPesel]"]').on('change', function() {
    $('[name="personal_data[pesel]"]', this.form.elements).prop('disabled', $(this).prop('checked'));
    let fields = [
        '[name="personal_data[street]"]',
        '[name="personal_data[streetNr]"]',
        '[name="personal_data[flatNr]"]',
        '[name="personal_data[zipCode]"]',
        '[name="personal_data[city]"]',
    ];
    $(fields.join(', '), this.form.elements).closest('.form-group').prop('disabled', !$(this).prop('checked'));
}).trigger('change');

$('.winner-form').on('submit-success', function(event, data) {
    setRegisteredData(data, 'section#winner-thanks');

    // page swap
    $('#winner').remove();
    $('#winner-thanks').removeClass('d-none');
    $('#winner-thanks').addClass('show');

    $('html, body').animate({
        scrollTop: 0
    }, 300);

    // modal (uncomment if you need modal instead of page swap)
    //$('#modal-form-winner').modal('show');
});

$('.contact-form').on('submit-success', function(event, data) {
    // setRegisteredData(data, 'section#contact');

    // page swap
    $('.page').addClass('d-none');
    $('.page-thank-you').removeClass('d-none');
    $('.page-thank-you').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    //$('#modal-form-contact').modal('show');
});