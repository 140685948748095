// Define datepicker options
const datepicker = {
	options: {
		default: {
			language: "pl",
			format: "dd.mm.yyyy",
			clearBtn: true,
			maxViewMode: 3,
			startDate: new Date('01/01/1900'),
			endDate: new Date(),
			defaultViewDate: new Date(),
		},
		bill: {
			language: "pl",
			format: "dd.mm.yyyy",
			clearBtn: true,
			maxViewMode: 1,
			defaultViewDate: new Date(),
            startDate: new Date($('#personal_data_billDate').attr('min')),
            endDate: new Date($('#personal_data_billDate').attr('max')),
		},
		dob: {
			language: "pl",
			format: "dd.mm.yyyy",
			clearBtn: true,
			maxViewMode: 2,
			startDate: new Date('01/01/1900'),
			endDate: new Date(),
			defaultViewDate: {
				year: 2000,
				month: 0,
				day: 1
			},
		}
	}
};

export default datepicker;