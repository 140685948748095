import './validator/initialisation';

let debugData = '';

$('#upload-file-modal').on('show.bs.modal', function () {
    if (window.innerWidth < 992) {
        const scannerUrl = $(this).find('.scanner-container').attr('data-url');

        $(this).find('.scanner-container').append(
            `<iframe width="100%" height="auto" id="scanner" style="width: 100%; height: 100%;" src="${scannerUrl}" allow="camera ${scannerUrl}; microphone ${scannerUrl}/"></iframe>`
        );
    }
});

$('#upload-file-modal').on('hide.bs.modal', function () {
    if (window.innerWidth < 992) {
        $(this).find('.scanner-container iframe').remove();
    }
});

export function getRecaptcha() {
	let url = new URL(document.querySelector('script[src^="https://www.google.com/recaptcha/api.js"]').src);
	let params = new URLSearchParams(url.search);
	return grecaptcha.execute(params.get('render'));
};

$.fn.ajaxInvalidFeedback = function(options) {
	return this.each(function() {
		let object = $(this).data('ajaxInvalidFeedback');
		if (typeof options == 'object') {
			$(this).addClass('has-invalid-feedback-ajax').after(`
				<div class='invalid-feedback invalid-feedback-ajax d-block'>${options.error}</div>
			`);
			let destroyHandler = () => $(this).ajaxInvalidFeedback('destroy');
			$(this).on('change input', destroyHandler);
			$(this.form).on('submit', destroyHandler);
			$(this).data('ajaxInvalidFeedback', {destroyHandler});
		} else if (options === 'destroy') {
			$(this).off('change input', object.destroyHandler).removeClass('has-invalid-feedback-ajax').siblings('.invalid-feedback-ajax').remove();
			$(this.form).off('submit', object.destroyHandler);
			$(this).data('ajaxInvalidFeedback', null);
		}
	});
};

$.fn.toggleValid = function (valid) {
    this.each((i, element) => element.setCustomValidity(valid ? "" : 'invalid'));
};

export function processFormErrors(errors, form) {
	for (let fieldName in errors) {
		if (fieldName == form.name) {
			for (let error of errors[fieldName]) {
				alert(error);
			}
		} else {
			let error = errors[fieldName].join('<br>');
			$(form.elements[fieldName]).ajaxInvalidFeedback({error: error});
		}
	}
};

export async function setRecaptchaResponse(form) {
    if ($(form).data('recaptcha') && !$(form.elements).filter('.g-recaptcha-response').length) {
		let fieldName = typeof $(form).data('recaptcha') == 'string' ? $(form).data('recaptcha') : 'g-recaptcha-response';
        $(form).append(`<input type="hidden" name="${fieldName}" class="g-recaptcha-response">`);
    }
    if ($(form.elements).filter('.g-recaptcha-response').length) {
        $(form.elements).filter('.g-recaptcha-response').val(await getRecaptcha());
    }
};

$('.ajax-form').on('submit', async function(event) {
	event.preventDefault();
	$(this.elements).filter('[type=submit]').prop('disabled', true);
	await setRecaptchaResponse(this);

	try {
		var data = await $.ajax({
			type: 'post',
			url: this.action,
			data: new FormData(this),
			processData: false,
			contentType: false,
		});
	} catch (xhr) {
		let errorEvent = $.Event('submit-error');
		$(this).trigger(errorEvent, xhr);
		if (!errorEvent.isDefaultPrevented()) {
			if (xhr.responseJSON && xhr.responseJSON.errors) {
				processFormErrors(xhr.responseJSON.errors, this);
			} else {
				alert("Wystąpił nieznany błąd, spróbuj ponownie później.");
			}
		}
		$(this).find('.invalid-feedback:visible:first')[0]?.scrollIntoView({ behavior: "smooth", block: 'center' });
		return;
	} finally {
		$(this.elements).filter('[type=submit]').prop('disabled', false);
	}
	let successEvent = $.Event('submit-success');
	$(this).trigger(successEvent, data);
	if (!successEvent.isDefaultPrevented()) {
		this.reset();
		$(this).removeClass('was-validated');
		$(this).find('.has-invalid-feedback-ajax').ajaxInvalidFeedback('destroy');
		$(this).find('.is-valid').removeClass('is-valid');
		$(this).find('.is-invalid').removeClass('is-invalid');
		$(this).find('.form-file .btn-remove').hide();
		$(this).find('img[data-src]').each(function() {
			$(this).attr('src', $(this).data('src'));
		});
		if (data.redirect) location.href = data.redirect;
	};
});

$('[name="billTypeCheckboxParagon"]').on('change', function () {
    if (window.innerWidth < 992) {
        if ($('[name="billTypeCheckboxParagon"]:checked').val() === 'Faktura') {
            $('.scanner-container').find('iframe').remove();
            $('.scanner-container').addClass('d-none');
            $('label[for="file_paragon"]').removeClass('d-none');
        } else {
            $('label[for="file_paragon"]').addClass('d-none');
            $('.scanner-container').removeClass('d-none');
            const scannerUrl = $('.scanner-container').attr('data-url');
            $('.scanner-container').append(
                `<iframe width="100%" height="auto" id="scanner" style="width: 100%; height: 100%;" src="${scannerUrl}" allow="camera ${scannerUrl}; microphone ${scannerUrl}/"></iframe>`
            );
        }
    }
});

$('#reload-file, #file_paragon').on('change', async function (e) {
    const file = e.target.files[0] || e.originalEvent.target.files[0];
    let formData = new FormData();
    const size = file.size / 1000 /1000;
    const self = this;

    $('input[name="uuid"]').remove();

    const billType = $('[name="billTypeCheckboxParagon"]:checked').val();
    $('form').append(`<input type="hidden" name="personal_data[billType]" value="${billType}">`);

    $('.landing').addClass('d-none');
    $('.application').addClass('d-none');
    $('#upload-file-modal').modal('hide');
    $('.loading').addClass('show');
    $('.s-hero__loading').addClass('show');

    $('html, body').animate({
        scrollTop: 0
    }, 300);

    if (billType === 'Paragon') {
        $('input[name="personal_data[billPos]"]').prop('disabled', false);
        if (size <= 8) {
            formData.append('file', file);
            formData.set('recaptcha', await getRecaptcha());
            formData.set('_token', $(this).data('token'));

            if (debugData) {
                formData.set('debugData', debugData);
                debugData = null;
            }

            $('#upload-file-modal .content .file-error').remove();

            try {
                $.ajax({
                    url: '/api/receipt',
                    type: 'POST',
                    data: formData,
                    processData: false,
                    contentType: false,
                    complete: function (response) {
                        const data = response.responseJSON;
                        $('.loading').removeClass('show');

                        if (data) {
                            if (data.status === 'error') {
                                $('.loading').removeClass('show');
                                $('.s-hero__loading').removeClass('show');
                                $('.landing').removeClass('d-none');
                                $('#upload-file-modal').modal('show');

                                $('#upload-file-modal .content').append(`<div class="invalid-feedback d-block file-error">${data.message}</div>`);
                            } else {
                                $('.s-hero__loading').removeClass('show');
                                $('.application').removeClass('d-none');
                                $('.application').addClass('show');

                                const reader = new FileReader();
                                reader.onload = (event) => {
                                    $('.s-hero__bill-image img').prop("src", event.target.result);
                                    if (!file.type.includes("image")) {
                                        $('.s-hero__bill-image img').prop("src", $('.s-hero__bill-image img').data("src"));
                                    }
                                };
                                reader.readAsDataURL(file);

                                $('#file-name').val(file.name);

                                let container = new DataTransfer();
                                container.items.add(file);
                                document.querySelector('#personal_data_files_paragon').files = container.files;
                                $('#personal_data_files_paragon').trigger('change');

                                if (data.status !== 'noresult') {
                                    $('form').append(`<input type="hidden" name="uuid" value="${data.uuid}">`);
                                    $('form').append(`<input type="hidden" name="ocrTime" value="${data.ocrTime}">`);
                                    $('form').append(`<input type="hidden" name="ocrUuid" value="${data.ocrUuid}">`);
                                }

                                $.each(data.billData, function (name, item) {
                                    $(`input[name="personal_data[${name}]"]`).val(item);
                                });
                            }
                        } else {
                            $('.landing').removeClass('d-none');
                            $('#upload-file-modal').modal('show');
                            $('.loading').removeClass('show');
                            $('.s-hero__loading').removeClass('show');

                            $('#upload-file-modal .content').append('<div class="invalid-feedback d-block file-error">Wystąpi nieznany błąd, proszę spróbować jeszcze raz.</div>');
                        }
                    }
                });
            } catch (xhr) {
                $('.loading').removeClass('show');
                $('.s-hero__loading').removeClass('show');
                $('.landing').removeClass('d-none');
                $('#upload-file-modal').modal('show');

                $('#upload-file-modal .content').append('<div class="invalid-feedback d-block file-error">Plik jest niepoprawny.</div>');
            }
        } else {
            $('.landing').removeClass('d-none');
            $('#upload-file-modal').modal('show');
            $('.loading').removeClass('show');
            $('.s-hero__loading').removeClass('show');

            $('#upload-file-modal .content').append('<div class="invalid-feedback d-block file-error">Plik jest za duży.</div>');
        }
    } else {
        $('input[name="personal_data[billPos]"]').prop('disabled', true);
        const reader = new FileReader();
        reader.onload = (event) => {
            $('.s-hero__bill-image img').prop("src", event.target.result);
            if (!file.type.includes("image")) {
                $('.s-hero__bill-image img').prop("src", $('.s-hero__bill-image img').data("src"));
            }
        };
        reader.readAsDataURL(file);

        $('#file-name').val(file.name);

        let container = new DataTransfer();
        container.items.add(file);
        document.querySelector('#personal_data_files_paragon').files = container.files;
        $('#personal_data_files_paragon').trigger('change');

        $('.loading').removeClass('show');
        $('.s-hero__loading').removeClass('show');
        $('.application').removeClass('d-none');
        $('.application').addClass('show');
    }
});

window.addEventListener('message', function(event) {
    let scannerData;

    try {
        scannerData = JSON.parse(event.data);
    } catch (e) {
        scannerData = null;
    }

    if (scannerData && scannerData?.name === 'scannerImage') {
        debugData = scannerData.debugData;
        var file = dataURLtoFile(scannerData.data, 'ocr.png');
        let container = new DataTransfer();
        container.items.add(file);
        document.querySelector('#file_paragon').files = container.files;
        $('#file_paragon').trigger('change');
    }
});

function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

$('form#form-age-gate input').on('input', function() {
    let regex = new RegExp(/^[0-9]+$/);
    if (!regex.test(this.value)) {
        this.value = this.value.replace(/[^0-9]/g, '');
    }
    if (this.value.length === this.maxLength) {
        const index = $(this).closest('div').index();
        let nextInput = $('form#form-age-gate').find('input')[index + 1];
        if (nextInput !== null) {
            nextInput.focus();
        }
    }
})

$('#form-age-gate').on('submit', function (event) {
    if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
            'event': 'gate'
        });
    }
});

$('#application-form-modal').on('click', function () {
    $('#upload-file-modal').modal('show');
});

